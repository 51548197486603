import React, { createContext, useEffect, useState } from "react";
import { injected } from "../connectors";
import { useWeb3React } from "@web3-react/core";
import {
  ACTIVE_NETWORK,
  default_RPC_URL,
  factoryContractAdress,
  NetworkDetails,
  AdminOwner,
  ShieldexStakingContracAddress,
} from "../constants/";
import ShieldexStakingContracABI from "src/abis/ShieldexStakingContracABI.json";
import { toast } from "react-toastify";
import Web3 from "web3";
import { getContract, getWeb3ContractObject, getWeb3Obj } from "src/utils";
import KingShibaIDOFactoryABI from "src/abis/KingShibaIDOFactoryABI.json";
import KingShibaIDOInfoABI from "src/abis/KingShibaIDOInfoABI.json";
import KingShibaIDOPresaleABI from "src/abis/KingShibaIDOPresaleABI.json";
import moment from "moment";
import axios from "axios";
import apiConfig, { socketURL } from "src/config/apiConfig";
import KingShibaStakingABI from "src/abis/KingShibaStakingABI.json";
import IERC20ABI from "src/abis/IERC20ABI.json";
import multicall from "src/utils/multicall";
import { isAddress } from "ethers/lib/utils";
import { RestaurantMenu } from "@material-ui/icons";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, deactivate, account, library, chainId } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [errorMsg] = useState("");
  const [userData, setUserData] = useState();
  const [connectwalletBalance, setConnectwalletBalance] = useState(0);
  const [numberOfPools, setNumberOfPools] = useState(0);
  const [poolList, setPoolList] = useState([]);
  const [userPoolList, setUserPoolList] = useState([]);
  const [livePoolList, setLivePoolList] = useState([]);
  const [closePoolList, setClosePoolList] = useState([]);
  const [premiumLists, setPremiumLists] = useState([]); // premiumLists,nonPremiumLists,fairLists,
  // console.log(
  //   "==========================>>>>>>>>>>>>>>>>>> user premiumLists",
  //   premiumLists
  // );
  const [nonPremiumLists, setNonPremiumLists] = useState([]);
  const [fairLists, setFairLists] = useState([]);
  const [walletAddress, setWalletAddress] = useState("");
  const [stackingPoolList, setStackingPoolList] = useState([]);
  // console.log(
  //   "stackingPoolList__________-----------------------",
  //   stackingPoolList
  // );
  const [liveStakingPools, setLiveStakingPools] = useState([]);
  const [FinishedStakingPools, setFinishedStakingPools] = useState([]);
  const [stackContractListAPI, setStackContractListAPI] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [usdPrice, setUsdPrice] = useState(0);
  const [poolListAPI, setPoolListAPI] = useState([]);
  const [searchToken, setSearchToken] = useState("");
  const [searchToken1, setSearchToken1] = useState("");
  const [view, setView] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [poolLoader, setpoolLoader] = useState(false);
  const [search1, setSearch1] = useState("");
  const [saleEndFair, setsaleEndFair] = React.useState([]);
  const [saleLiveFair, setsaleLiveFair] = React.useState([]);
  const [cancledFairFair, setcancledFairFair] = React.useState([]);
  const [upcomingFair, setupcomingFair] = React.useState([]);
  const [filledEndedFair, setfilledEndedFair] = React.useState([]);
  const [cancledFair, setcancledFair] = React.useState([]);

  const [saleEndFairPre, setsaleEndFairPre] = React.useState([]);

  const [saleLiveFairPre, setsaleLiveFairPre] = React.useState([]);

  const [upcomingFairPre, setupcomingFairPre] = React.useState([]);
  const [filledEndedFairPre, setfilledEndedFairPre] = React.useState([]);
  const [cancledFairPre, setcancledFairPre] = React.useState([]);
  const [feesList, setFeesList] = React.useState([]);
  const [membersTypes, setMembersTypes] = React.useState(0);
  const [membersName, setMembersName] = React.useState("");
  const [roundData, setroundData] = useState([]);

  useEffect(() => {
    if (account) {
      GetTotalStaked();
    }
  }, [account, roundData]);
  // console.log(
  //   roundData.filter((data) => data.planType == "BRONZE")[0],
  //   "roundData.filter((data) => data.planType ",
  //   roundData.filter((data) => data.planType == "BRONZE")
  // );
  const GetTotalStaked = async () => {
    try {
      const contractObj = await getWeb3ContractObject(
        ShieldexStakingContracABI,
        ShieldexStakingContracAddress,
        default_RPC_URL
      );

      const userInfo = await contractObj.methods.userInfo(account).call();
      // const rewardToken1 = await contractObj.methods.rewardToken().call();
      // console.log("userInfo", userInfo);
      const UserStakedData = web3.utils.fromWei(userInfo?.amount.toString());
      // console.log(
      //   userInfo,
      //   "UserStakedData--------++++++++UserStakedData",
      //   rewardToken1
      // );

      let membersrounOf;
      let membersrounName;

      if (
        roundData.filter((data) => data.planType == "BRONZE")[0]?.planName ==
        "BRONZE"
      ) {
        if (
          UserStakedData <=
          roundData.filter((data) => data.planType == "BRONZE")[0]
            ?.stackingAmount
        ) {
          membersrounOf = 0;
          membersrounName = "0";
        }
      }
      /************************************ */
      // if(roundData.filter((data) => data.planType == "BRONZE")[0]
      // ?.pla)
      if (
        UserStakedData <=
          roundData.filter((data) => data.planType == "SILVER")[0]
            ?.stackingAmount &&
        UserStakedData >
          roundData.filter((data) => data.planType == "BRONZE")[0]
            ?.stackingAmount
      ) {
        membersrounOf = roundData.filter((data) => data.planType == "BRONZE")[0]
          ?.stackingAmount;
        membersrounName = roundData.filter(
          (data) => data.planType == "BRONZE"
        )[0]?.planName;
        // membersrounName = "gold";
      }
      /*********************************** */
      if (
        UserStakedData <=
          roundData.filter((data) => data.planType == "GOLD")[0]
            ?.stackingAmount &&
        UserStakedData >
          roundData.filter((data) => data.planType == "SILVER")[0]
            ?.stackingAmount
      ) {
        membersrounOf = roundData.filter((data) => data.planType == "SILVER")[0]
          ?.stackingAmount;
        membersrounName = roundData.filter(
          (data) => data.planType == "SILVER"
        )[0]?.planName;
        // membersrounName = "bronze";
      }

      /************************************* */

      if (
        UserStakedData <=
          roundData.filter((data) => data.planType == "DIAMOND")[0]
            ?.stackingAmount &&
        UserStakedData >
          roundData.filter((data) => data.planType == "GOLD")[0]?.stackingAmount
      ) {
        membersrounOf = roundData.filter((data) => data.planType == "GOLD")[0]
          ?.stackingAmount;
        // membersrounName = "silver";
        membersrounName = roundData.filter((data) => data.planType == "GOLD")[0]
          ?.planName;
      }

      /********************************************* */
      if (
        UserStakedData <=
          roundData.filter((data) => data.planType == "PLATINUM")[0]
            ?.stackingAmount &&
        UserStakedData >
          roundData.filter((data) => data.planType == "DIAMOND")[0]
            ?.stackingAmount
      ) {
        membersrounOf = roundData.filter(
          (data) => data.planType == "DIAMOND"
        )[0]?.stackingAmount;
        // membersrounName = "platinum";
        membersrounName = roundData.filter(
          (data) => data.planType == "DIAMOND"
        )[0]?.planName;
      }
      /*************************************************** */
      if (
        UserStakedData >
        roundData.filter((data) => data.planType == "PLATINUM")[0]
          ?.stackingAmount
      ) {
        membersrounOf = roundData.filter(
          (data) => data.planType == "PLATINUM"
        )[0]?.stackingAmount;
        // membersrounName = "diamond";
        membersrounName = roundData.filter(
          (data) => data.planType == "PLATINUM"
        )[0]?.planName;
      }
      /******************************** */
      setMembersTypes(membersrounOf);
      setMembersName(membersrounName);
      // console.log(
      //   "UserStakedData----------------UserStakedData--",
      //   web3.utils.fromWei(UserStakedData)
      // );
    } catch (error) {
      console.log("error", error);
    }
  };
  // const [filters, setFilters] = React.useState("all");
  // console.log(
  //   feesList,
  //   "+++++++++++++++++++=feesList____-----------------------------"
  // );
  const token = sessionStorage.getItem("token");

  const ListFees = async (searchKey) => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.listFees,
      });
      if (res.data.statusCode === 200) {
        setFeesList(res.data.result[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ListMembersData = async (searchKey) => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.listMembershipPlan,
      });
      if (res.data.statusCode === 200) {
        setroundData(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    ListMembersData();
    ListFees();
  }, []);

  const getCurrentUSDPrice = async () => {
    try {
      const res = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd"
      );
      if (res.status === 200) {
        // console.log("res", res.data.wbnb.usd);
        setUsdPrice(res.data.wbnb.usd);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    getCurrentUSDPrice();
  }, []);

  useEffect(() => {
    if (account) {
      connectWalletAPICall();
      setWalletAddress(account);
    } else {
      setIsLogin(false);
      setTokenSession(null);
    }
  }, [account]);
  // console.log("account", account);

  const connectWalletAPICall = async () => {
    try {
      // console.log("account", account);
      // const res = await axios.post(apiConfig.connectWallet, {
      //   walletAddress: account,
      // });
      const res = await axios({
        method: "POST",
        url: apiConfig.connectWallet,
        // headers: {
        //   authorization: `Bearer ${window.localStorage.getItem("token")}`,
        // },
        data: {
          walletAddress: account,
        },
      });
      if (res.data.statusCode === 200) {
        setTokenSession(res.data.result.token);
        setUserData(res.data.result);
        // console.log("account", account);
        setIsLogin(true);
      } else {
        toast.error(res.data.responseMessage);
        setTokenSession();
      }
    } catch (error) {
      setTokenSession();
      console.log("ERROR", error);
      toast.error(error.message);
    }
  };

  //NETWORK CHECK AND SWICH NETWORK

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };

  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setConnectwalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, library]);

  const connectToWallet = () => {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log("ERROR", error);
          const errorMSG = error.message; //+ ' Please install Metamask';
          toast.warn(errorMSG);
          // alert(errorMSG);
          activate(injected);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  const poolListAPIHandler = async (search11) => {
    try {
      let SearchT =
        search11 == "" ? false : search11 == undefined ? false : true;
      if (SearchT) {
        // console.log(SearchT);
        let search;
        if (search11 != "") {
          search = search11;
        } else {
          search = [];
        }
        setPoolList([]);
        setpoolLoader(true);
        const res = await axios.get(apiConfig.presaleList, {
          params: { search },
        });
        if (res.data.statusCode === 200) {
          const list = res.data.result;
          // console.log("lists----------", list);
          // console.log(list);
          setPoolListAPI(list);
          const web3 = await getWeb3Obj(default_RPC_URL);
          let array = [];
          const add = await Promise.all(
            list.map(async (listData) => {
              const desA = isAddress(listData.presaleAddress);
              // console.log("239 ==>", desA);
              if (desA === true) {
                if (listData.presaleAddress) {
                  const calls = [
                    {
                      address: listData.presaleAddress,
                      name: "totalTokens",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "totalInvestorsCount",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "totalCollectedWei",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "tokensLeft",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "investmentToken",
                    },
                    {
                      address: listData.presaleAddress,
                      name: "presaleCreatorAddress",
                    },

                    {
                      address: listData.presaleAddress,
                      name: "presaleCancelled",
                    },
                  ];

                  const [
                    totalTokens,
                    totalInvestorsCount,
                    totalCollectedWei,
                    tokensLeft,
                    investmentToken,
                    presaleCreatorAddress,
                    presaleCancelled,
                  ] = await multicall(KingShibaIDOPresaleABI, calls);

                  let obj = {
                    ...listData,
                    presaleCreatorAddress: presaleCreatorAddress,
                    presaleCancelled: presaleCancelled[0],
                    investmentToken: investmentToken,
                    contractAddress: listData.presaleAddress,
                    presaleAddress: listData.presaleAddress,
                    saleTitle: listData.title,
                    openTime: listData.startTime,
                    closeTime: listData.endTime,
                    totalTokens: web3.utils.fromWei(
                      totalTokens.toString(),
                      "ether"
                    ),
                    ABCDtotalInvestorsCount: totalInvestorsCount.toString(),

                    totalCollectedWei: web3.utils.fromWei(
                      totalCollectedWei.toString(),
                      "ether"
                    ),
                    tokensLeft: web3.utils.fromWei(
                      tokensLeft.toString(),
                      "ether"
                    ),
                  };
                  array.push(obj);
                  // console.log("array data---------- obj", obj);
                  // return obj;
                }
              }
            })
          );
          // console.log("-----------------------------array", array);
          // console.log("-----------------------------add", add);
          setPoolList(array);

          setpoolLoader(false);
          // setSearch1("");
        }
      } else {
        let search;
        if (search11 != "") {
          search = search11;
        } else {
          search = [];
        }
        setPoolList([]);
        setpoolLoader(true);
        const res = await axios.get(apiConfig.presaleList, {});
        if (res.data.statusCode === 200) {
          const list = res.data.result;
          // console.log("lists----------", list);
          // console.log(list);
          setPoolListAPI(list);
          const web3 = await getWeb3Obj(default_RPC_URL);
          let array = [];
          const add = await Promise.all(
            list.map(async (listData) => {
              const desA = isAddress(listData.presaleAddress);
              // console.log("239 ==>", desA);

              const multi = await getWeb3ContractObject(
                KingShibaIDOPresaleABI,
                listData.presaleAddress,
                default_RPC_URL
              );
              // console.log("contract----------", multi);
              if (
                listData.presaleAddress !=
                "0xbbebfcd82cdd310707fc23c804543e487eae3b9a"
              ) {
                if (
                  listData.presaleAddress !=
                  "0xb9C8Bef1FA01FdcaD7fEf2EdA7C9BE56cb494aFb"
                ) {
                  if (desA === true) {
                    if (listData.presaleAddress) {
                      const calls = [
                        {
                          address: listData.presaleAddress,
                          name: "totalTokens",
                        },

                        {
                          address: listData.presaleAddress,
                          name: "totalInvestorsCount",
                        },
                        {
                          address: listData.presaleAddress,
                          name: "totalCollectedWei",
                        },
                        {
                          address: listData.presaleAddress,
                          name: "tokensLeft",
                        },
                        {
                          address: listData.presaleAddress,
                          name: "investmentToken",
                        },
                        {
                          address: listData.presaleAddress,
                          name: "presaleCreatorAddress",
                        },
                        {
                          address: listData.presaleAddress,
                          name: "presaleCancelled",
                        },
                      ];

                      const [
                        totalTokens,
                        totalInvestorsCount,
                        totalCollectedWei,
                        tokensLeft,
                        investmentToken,
                        presaleCreatorAddress,
                        presaleCancelled,
                      ] = await multicall(KingShibaIDOPresaleABI, calls);

                      let obj = {
                        ...listData,
                        presaleCreatorAddress: presaleCreatorAddress,
                        presaleCancelled: presaleCancelled[0],
                        investmentToken: investmentToken,
                        contractAddress: listData.presaleAddress,
                        presaleAddress: listData.presaleAddress,
                        saleTitle: listData.title,
                        openTime: listData.startTime,
                        closeTime: listData.endTime,
                        totalTokens: web3.utils.fromWei(
                          totalTokens.toString(),
                          "ether"
                        ),
                        ABCDtotalInvestorsCount: totalInvestorsCount.toString(),
                        totalCollectedWei: web3.utils.fromWei(
                          totalCollectedWei.toString(),
                          "ether"
                        ),
                        tokensLeft: web3.utils.fromWei(
                          tokensLeft.toString(),
                          "ether"
                        ),
                      };
                      console.log("obj----------------------------", obj);
                      array.push(obj);
                      // return obj;
                    }
                  }
                }
              }
            })
          );
          console.log("-----------------------------array", array);
          // console.log("-----------------------------add", add);
          setPoolList(array);

          setpoolLoader(false);
          // setSearch1("");
        }
      }
    } catch (error) {
      // setSearch1("");
      setpoolLoader(false);
      console.log("ERROR", error);
    }
  };

  const poolListingData = async () => {
    const web3 = await getWeb3Obj(default_RPC_URL);
    // setPoolList([]);
    try {
      const contract = new web3.eth.Contract(
        KingShibaIDOFactoryABI,
        factoryContractAdress
      );
      const poolDataListing = await contract.methods.TESTIDO().call();
      const poolListingObj = new web3.eth.Contract(
        KingShibaIDOInfoABI,
        poolDataListing
      );
      const presalesCount = await poolListingObj.methods
        .getPresalesCount()
        .call();
      // console.log(
      //   "-------presalesCount----------- poolDataListing",
      //   poolDataListing
      // );
      // console.log(
      //   "-------presalesCount----------- presalesCount",
      //   presalesCount
      // );
      setNumberOfPools(presalesCount);
      for (var i = presalesCount - 1; i >= 0; i--) {
        var presaleAddress = await poolListingObj.methods
          .getPresaleAddress(i)
          .call();

        // console.log(
        //   "-------presalesCount----------- presaleAddress",
        //   presaleAddress
        // );
        const calls = [
          {
            address: presaleAddress,
            name: "totalTokens",
          },
          {
            address: presaleAddress,
            name: "closeTime",
          },
          {
            address: presaleAddress,
            name: "saleTitle",
          },
          {
            address: presaleAddress,
            name: "openTime",
          },
          {
            address: presaleAddress,
            name: "totalCollectedWei",
          },
          {
            address: presaleAddress,
            name: "tokensLeft",
          },
          {
            address: presaleAddress,
            name: "investmentToken",
          },
          {
            address: presaleAddress,
            name: "presaleCreatorAddress",
          },
        ];

        const [
          totalTokens,
          closeTime,
          saleTitle,
          openTime,
          totalCollectedWei,
          tokensLeft,
          investmentToken,
          presaleCreatorAddress,
          // quoteTokenDecimals
        ] = await multicall(KingShibaIDOPresaleABI, calls);
        // console.log("saleTitle--------------primium", saleTitle);
        // const presaleAddressContractObj = new web3.eth.Contract(
        //   KingShibaIDOPresaleABI,
        //   presaleAddress
        // );

        // const closeTime = await presaleAddressContractObj.methods
        //   .closeTime()
        //   .call();

        // const saleTitle = await presaleAddressContractObj.methods
        //   .saleTitle()
        //   .call();
        // const openTime = await presaleAddressContractObj.methods
        //   .openTime()
        //   .call();

        // const totalTokens = await presaleAddressContractObj.methods
        //   .totalTokens()
        //   .call();
        // const totalCollectedWei = await presaleAddressContractObj.methods
        //   .totalCollectedWei()
        //   .call();
        // const tokensLeft = await presaleAddressContractObj.methods
        //   .tokensLeft()
        //   .call();

        // const investmentToken = await presaleAddressContractObj.methods
        //   .investmentToken()
        //   .call();
        // const presaleCreatorAddress = await presaleAddressContractObj.methods
        //   .presaleCreatorAddress()
        //   .call();

        let obj = {
          presaleCreatorAddress: presaleCreatorAddress,
          investmentToken: investmentToken,
          contractAddress: presaleAddress,
          presaleAddress: presaleAddress,
          saleTitle: web3.utils.hexToAscii(saleTitle[0]),
          openTime: openTime,
          closeTime: closeTime,
          totalTokens: web3.utils.fromWei(totalTokens.toString(), "ether"),
          totalCollectedWei: web3.utils.fromWei(
            totalCollectedWei.toString(),
            "ether"
          ),
          tokensLeft: web3.utils.fromWei(tokensLeft.toString(), "ether"),
        };
        // setPoolList((prev) => [...prev, obj]);

        // console.log("-------presalesCount----------- obj", obj);
        setpoolLoader(false);
        // console.log("------++++++++++++++==========obj premimum 123", obj);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    // const cancelTokenSource = axios.CancelToken.source();

    poolListAPIHandler();
    poolListingData();
    // return () => {
    //   cancelTokenSource.cancel();
    // };
  }, []);
  // USER POOLS
  useEffect(() => {
    if (account) {
      const list = poolList.filter(
        (data) => data.presaleCreatorAddress == account
      );
      setUserPoolList(list);
    }
  }, [poolList, account]);

  // Live POOLS
  useEffect(() => {
    const list = poolList.filter(
      (data) =>
        Number(data.closeTime) > moment().unix() &&
        Number(data.openTime) < moment().unix()
    );
    const closedList = poolList.filter(
      (data) => Number(data.closeTime) < moment().unix()
    );
    // poolList

    // console.log(
    //   "==========================>>>>>>>>>>>>>>>>>> poolList premiumLists",
    //   poolList
    // );
    const premiumList1 = poolList.filter((data) => data.premium === true);
    const NonPremiumList = poolList.filter((data) => data.premium === false);
    const fairList = poolList.filter((data) => data.premium === false);
    setLivePoolList(list);
    setClosePoolList(closedList);
    setPremiumLists(premiumList1);
    setNonPremiumLists(NonPremiumList);
    setFairLists(fairList);

    /* **************** Fair * List **************** */
    const saleEnds = fairList.filter(
      (data) => Number(data.closeTime) < moment().unix()
    );
    const saleLive = fairList.filter(
      (data) =>
        Number(data.closeTime) > moment().unix() &&
        Number(data.openTime) < moment().unix()
    );
    const upcomings = fairList.filter(
      (data) => Number(data.openTime) > moment().unix()
    );
    // console.log("saleEnd   ----saleEnds----------", saleEnds);
    const cancledFair = fairList.filter(
      (data) => data.presaleCancelled == true
    );
    // console.log(saleEnds, "saleEnds------------search1");
    /* **************** Fair * List **************** */
    setupcomingFair(upcomings);
    setsaleEndFair(saleEnds);
    setsaleLiveFair(saleLive);
    setcancledFairFair(cancledFair);
    /* **************** Fair *Filled* List **************** */
    const filledListFair = fairList.filter(
      (data) =>
        Math.ceil(
          ((parseFloat(data?.totalTokens) - parseFloat(data?.tokensLeft)) /
            data?.totalTokens) *
            100
        ) == 100
    );
    // console.log("------ Fair *Filled* List ---------------", filledListFair);
    /* **************** Fair *Filled* List **************** */
    setfilledEndedFair(filledListFair);
    /* **************** Fair *Filled* List **************** */
    /* **************** Fair * List **************** */

    /* **************** ------pre * List------ **************** */
    const saleEndsPre = premiumList1.filter(
      (data) => Number(data.closeTime) < moment().unix()
    );
    const saleLivePre = premiumList1.filter(
      // (data) => Number(data.closeTime) < moment().unix()
      (data) =>
        Number(data.closeTime) > moment().unix() &&
        Number(data.openTime) < moment().unix()
    );
    const cancledPreFair = premiumList1.filter(
      (data) => data.presaleCancelled == true
    );
    const upcomingsPre = premiumList1.filter(
      (data) => Number(data.openTime) > moment().unix()
    );
    // console.log("saleEndsPre------------search1", saleEndsPre);
    /* **************** Fair * List **************** */
    setupcomingFairPre(upcomingsPre);
    setsaleEndFairPre(saleEndsPre);
    setsaleLiveFairPre(saleLivePre);
    setcancledFairPre(cancledPreFair);
    /* **************** Pre *Filled* List **************** */
    const filledListFairPre = premiumList1.filter(
      (data) =>
        Math.ceil(
          ((parseFloat(data?.totalTokens) - parseFloat(data?.tokensLeft)) /
            data?.totalTokens) *
            100
        ) == 100
    );
    // console.log("------ pre *Filled* List ---------------", filledListFairPre);
    /* **************** pre *Filled* List **************** */
    setfilledEndedFairPre(filledListFairPre);
    /* **************** ptr *Filled* List **************** */
  }, [poolList, account]);

  const getStackingListAPIHandler = async () => {
    setLiveStakingPools([]);
    setStackContractListAPI([]);
    try {
      const res = await axios.get(apiConfig.stackingList, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      if (res.data.statusCode === 200) {
        let date123 = moment(data.createdAt).unix();
        console.log("date123 ---- 1669185157 ", date123);
        let dataList = res.data.result.filter(
          (data) => moment(data.createdAt).unix() > 1669377417
        );
        console.log("date123 ---- dataList", dataList);
        // console.log("tackingPoolList------------- dataList", dataList);
        const key = "createdAddress";
        const arrayUniqueByKey = [
          ...new Map(dataList.map((item) => [item[key], item])).values(),
        ];
        setStackContractListAPI(arrayUniqueByKey);
      }
    } catch (error) {
      console.log("ERROR");
    }
  };

  // console.log(liveStakingPools, "stackingPoolList-------------");

  useEffect(() => {
    if (account) {
      getStackingListAPIHandler();
    }
  }, [account]);

  const getStakingListBlockChainHandler = async () => {
    const web3 = await getWeb3Obj();
    for (let i = 0; i < stackContractListAPI.length; i++) {
      try {
        const ERCobjStackAddress = await getWeb3ContractObject(
          IERC20ABI,
          stackContractListAPI[i].stackAddress
        );
        const dgfhjhkhf = await getWeb3ContractObject(
          KingShibaStakingABI,
          stackContractListAPI[i].createdAddress
        );
        console.log("dgfhjhkhf-------", dgfhjhkhf);

        const rewardsAddresss = await dgfhjhkhf.methods.rewardToken().call();

        // console.log("dgfhjhkhf------- rewardsAddresss", rewardsAddresss);
        const ERCobjRewardAddress = await getWeb3ContractObject(
          IERC20ABI,
          rewardsAddresss
        );

        const calls = [
          {
            address: stackContractListAPI[i].createdAddress,
            name: "rate",
          },
          {
            address: stackContractListAPI[i].createdAddress,
            name: "totalStaked",
          },
          {
            address: stackContractListAPI[i].createdAddress,
            name: "pool",
          },
        ];

        const [rewardPerBlock, startBlock, bonusEndBlock] = await multicall(
          KingShibaStakingABI,
          calls
        );
        console.log("dgfhjhkhf-------", bonusEndBlock);
        let pendingReward = 0;

        const stakedTokenName = await ERCobjStackAddress.methods.name().call();
        const rewardTokenName = await ERCobjRewardAddress.methods.name().call();

        let obj = {
          _id: stackContractListAPI[i]._id,
          poolImage: stackContractListAPI[i].poolImage,
          startBlock: Number(startBlock),
          bonusEndBlock: Number(bonusEndBlock),
          withdrawInterval: bonusEndBlock.withdrawInterval.toString(),
          harvestInterval: bonusEndBlock.harvestInterval.toString(),
          // lastRewardBlock: bonusEndBlock.lastRewardBlock.toString(),
          rewardPerBlock: Number(rewardPerBlock),
          pendingReward: Number(web3.utils.fromWei(pendingReward.toString())),
          stakedTokenName: stakedTokenName,
          rewardTokenName: rewardTokenName,
          stakedToken: stackContractListAPI[i].stackAddress,
          rewardToken: stackContractListAPI[i].rewardAddress,
          createdAddress: stackContractListAPI[i].createdAddress,
        };
        // setStackingPoolList((prev) => [...prev, obj]);
        setLiveStakingPools((prev) => [...prev, obj]);
        console.log(obj, "suser. liveStakingPools------------->>>>>>>>>>>");
      } catch (error) {
        // console.log(" stackingPoolList-------------  763");
        console.log("---err--", error);
      }
    }
  };
  useEffect(() => {
    // console.log(
    //   " stackingPoolList------------- --stackContractListAPI - ",
    //   stackContractListAPI
    // );
    if (stackContractListAPI.length > 0) {
      // console.log(
      //   " stackingPoolList------------- ---stackContractListAPI - ",
      //   stackContractListAPI
      // );
      getStakingListBlockChainHandler();
    }
  }, [stackContractListAPI]);

  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL);
  const web3 = new Web3(httpProvider);
  const currentBlockNumber = web3.eth.getBlock("latest");

  useEffect(() => {
    const liveStak = stackingPoolList.filter(
      (data) =>
        Number(data.bonusEndBlock) > currentBlockNumber &&
        Number(data.startBlock) < currentBlockNumber
    );
    // console.log("---liveStak-------", liveStak);
    // setLiveStakingPools(liveStak);
  }, [stackingPoolList]);

  //setFinishedStakingPools
  useEffect(() => {
    const finishedStak = stackingPoolList.filter(
      (data) => Number(data.bonusEndBlock) < moment().unix()
    );
    // console.log("finishedStak", finishedStak);
    setFinishedStakingPools(finishedStak);
  }, [stackingPoolList]);

  useEffect(() => {
    // if (searchNetwork === 'Polygon') {
    //   getPolygonContractDetailsHandler(searchToken)
    // } else if (searchNetwork === 'ETH') {
    //   getETHContractDetailsHandler(searchToken)
    if (searchToken !== "") {
      // getBSCContractDetailHandler(searchToken);
      // sourceCodeFinder(searchToken);
    }
  }, [searchToken]);
  //bsc--------------------------------------

  const [searchTokenDetails, setSearchTokenDetails] = useState([]);
  const [isLoadingSearchedData, setIsLoadingSearchedData] = useState(0);
  const [isSearchTrue, setIsSearchTrue] = useState(false);
  const [networkGasPrice, setNetworkGasPrice] = useState([]);
  const [networkLiquidity, setNetworkLiquidity] = useState([]);
  const [networkHolders, setNetworkHolders] = useState([]);
  const [contractInitialData, setContractInitialData] = useState([]);
  const [humanSummary, setHumanSummary] = useState({});
  const [getBSCOwnerDetails, setGetBSCOwnerDetails] = useState([]);
  const [networkTaxCal, setNetworkCal] = useState([]);
  const [bscTransactionData, setBSCtransactionData] = useState([]);
  const [bscScanResult, setBscScanResult] = useState("");
  const [bscDetailstokenTransaction, setBscDetailstokenTransaction] = useState(
    []
  );
  const [bscDetailstransactions, setBscDetailstransactions] = useState([]);

  const getBSCContractDetailHandler = async (searchKey) => {
    BSCgetEstimateGasPriceHandler(searchToken);
    try {
      const res = await axios({
        method: "POST",
        url: apiConfig.getBSCContractDetail,
        headers: {
          token: token,
        },
        params: {
          limit: 10,
        },
        data: {
          contractAddress: searchKey,
        },
      });
      if (res.data.responseCode === 200) {
        setSearchTokenDetails(res.data.result);
        setIsLoadingSearchedData((prev) => prev + 10);
        // console.log("BSC 10  ---------1 ");
      }
    } catch (error) {
      console.log(error);
      toast.error("You have entered a wrong address");
      setIsLoadingSearchedData(0);
      setIsSearchTrue(false);
    }
  };
  const getBSCContractDetailHandler1 = async (searchKey1) => {
    // console.log(
    //   "---------------------searchToken1-searchToken1searchToken1searchToken1searchToken1",
    //   searchToken1
    // );
    BSCgetEstimateGasPriceHandler1(searchToken1);
    try {
      const res = await axios({
        method: "POST",
        url: apiConfig.getBSCContractDetail,
        headers: {
          token: token,
        },
        params: {
          limit: 10,
        },
        data: {
          contractAddress: searchKey1,
        },
      });
      if (res.data.responseCode === 200) {
        setSearchTokenDetails(res.data.result);
        // setIsLoadingSearchedData((prev) => prev + 10);
        // console.log("BSC 10  ---------1 ");
      }
    } catch (error) {
      console.log(error);
      toast.error("You have entered a wrong address");
      // setIsLoadingSearchedData(0);
      setIsSearchTrue(false);
    }
  };
  const BSCgetEstimateGasPriceHandler = async (searchKey) => {
    getBscLiquidityScanHandler(searchToken);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.BSCgetEstimateGasPrice,
        headers: {
          token: token,
        },
        params: {
          contractAddress: searchKey,
        },
      });
      if (res.data.responseCode === 200) {
        setNetworkGasPrice(res.data.result);
        setIsLoadingSearchedData((prev) => prev + 20);
        // console.log("BSC  20 ---------2 ");
      }
    } catch (error) {
      console.log(error);
      setIsLoadingSearchedData(0);
    }
  };
  const BSCgetEstimateGasPriceHandler1 = async (searchKey1) => {
    getBscLiquidityScanHandler1(searchToken1);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.BSCgetEstimateGasPrice,
        headers: {
          token: token,
        },
        params: {
          contractAddress: searchKey1,
        },
      });
      if (res.data.responseCode === 200) {
        setNetworkGasPrice(res.data.result);
        // setIsLoadingSearchedData((prev) => prev + 20);
        // console.log("BSC  20 ---------2 ");
      }
    } catch (error) {
      console.log(error);
      // setIsLoadingSearchedData(0);
    }
  };
  const getBscLiquidityScanHandler = async (searchKey) => {
    BSCholderScanHandler(searchToken);

    try {
      const credentials = {
        contractAddress: searchKey,
        // since: '2020-12-01T23:59:59',
        // till: '2022-01-07T23:59:59',
      };

      const res = await axios.post(apiConfig.getBscLiquidityScan, credentials, {
        headers: {
          token: token,
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      // if (res.data.responseCode === 200) {
      //   setNetworkLiquidity(res.data.result);
      //   setIsLoadingSearchedData((prev) => prev + 10);
      //   console.log("BSC 10  ---------3 ");
      // }
      // console.log(res);
      if (res.status === 200) {
        setNetworkLiquidity(res.data.result);
        setIsLoadingSearchedData((prev) => prev + 10);
        // console.log("BSC 10  ---------3 ");
      }
    } catch (error) {
      console.log(error);
      // toast.error('Something went wrong')
      setIsLoadingSearchedData(0);
    }
  };
  const getBscLiquidityScanHandler1 = async (searchKey1) => {
    BSCholderScanHandler1(searchToken1);

    try {
      const credentials = {
        contractAddress: searchKey1,
        // since: '2020-12-01T23:59:59',
        // till: '2022-01-07T23:59:59',
      };

      const res = await axios.post(apiConfig.getBscLiquidityScan, credentials, {
        headers: {
          token: token,
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      // if (res.data.responseCode === 200) {
      //   setNetworkLiquidity(res.data.result);
      //   setIsLoadingSearchedData((prev) => prev + 10);
      //   console.log("BSC 10  ---------3 ");
      // }
      // console.log(res);
      if (res.status === 200) {
        setNetworkLiquidity(res.data.result);
        // setIsLoadingSearchedData((prev) => prev + 10);
        // console.log("BSC 10  ---------3 ");
      }
    } catch (error) {
      console.log(error);
      // toast.error('Something went wrong')
      setIsLoadingSearchedData(0);
    }
  };
  const BSCholderScanHandler = async (searchKey) => {
    // buySellBSCFeePercentageHandler(searchToken);
    BSCInfoHandler(searchToken);
    try {
      // console.log(apiConfig.BSCholderScan);
      const res = await axios({
        method: "GET",
        url: apiConfig.BSCholderScan,
        headers: {
          token: token,
        },
        params: {
          contractAddress: searchKey,
        },
      });
      if (res.data.responseCode === 200) {
        setNetworkHolders(res.data.result);
        setIsLoadingSearchedData((prev) => prev + 10);
        // console.log("BSC  10 ---------4 ");
      }
    } catch (error) {
      console.log(error);
      // toast.error('Something went wrong')
      setIsLoadingSearchedData(0);
    }
  };
  const BSCholderScanHandler1 = async (searchKey1) => {
    // buySellBSCFeePercentageHandler1(searchToken1);
    BSCInfoHandler1(searchToken1);
    try {
      // console.log(apiConfig.BSCholderScan);
      const res = await axios({
        method: "GET",
        url: apiConfig.BSCholderScan,
        headers: {
          token: token,
        },
        params: {
          contractAddress: searchKey1,
        },
      });
      if (res.data.responseCode === 200) {
        setNetworkHolders(res.data.result);
        // setIsLoadingSearchedData((prev) => prev + 10);
        // console.log("BSC  10 ---------4 ");
      }
    } catch (error) {
      console.log(error);
      // toast.error('Something went wrong')
      setIsLoadingSearchedData(0);
    }
  };
  const BSCInfoHandler = async (searchKey) => {
    humanSummaryBSCHandler(searchToken);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.BSCInfo,
        headers: {
          token: token,
        },
        params: {
          contractAddress: searchKey,
          contractType: "Binance",
        },
      });
      // console.log("tokenInfo____________-----------------=++++++++", res);
      if (res.status === 200) {
        setContractInitialData(res?.data?.result);
        setIsLoadingSearchedData((prev) => prev + 10);
        console.log("BSC  10 ---------5 ");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const BSCInfoHandler1 = async (searchKey) => {
    console.log("b s c ----->", searchKey);
    humanSummaryBSCHandler1(searchToken1);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.BSCInfo,
        headers: {
          token: token,
        },
        params: {
          contractAddress: searchKey,
          contractType: "Binance",
        },
      });
      // console.log("tokenInfo____________-----------------=++++++++", res);
      if (res.status === 200) {
        setContractInitialData(res?.data?.result);
        // setIsLoadingSearchedData((prev) => prev + 10);
        console.log("BSC  10 ---------5 ");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const humanSummaryBSCHandler = async (searchKey) => {
    getBSCOwnerDetailsHandler(searchToken);
    requestContractDetailsHandler(searchToken);
    buySellBSCFeePercentageHandler(searchToken);
    // getBSCTrasactionDetails(searchToken);
    try {
      const res = await axios({
        method: "POST",
        url: apiConfig.humanSummaryBSC,
        headers: {
          token: token,
        },

        data: {
          contractAddress: searchKey,
        },
      });
      if (res.data.responseCode === 200) {
        setHumanSummary(res.data.result);
        setIsLoadingSearchedData((prev) => prev + 35);
        console.log("BSC 10  ---------6 ");
        setIsSearchTrue(false);
      }
    } catch (error) {
      setIsLoadingSearchedData(0);
      console.log(error);
      console.log("BSC 10  ---------6  humanSummaryBSCHandler ");
      toast.error("Contract address is not audited ");
      setSearchToken("");
    }
  };
  const humanSummaryBSCHandler1 = async (searchKey) => {
    console.log("b s c ----->", searchKey);
    getBSCOwnerDetailsHandler1(searchToken1);
    buySellBSCFeePercentageHandler(searchToken1);
    requestContractDetailsHandler(searchToken1);
    // getBSCTrasactionDetails(searchToken1);
    console.log("---------------------BSC 10", searchToken1);
    try {
      const res = await axios({
        method: "POST",
        url: apiConfig.humanSummaryBSC,
        headers: {
          token: token,
        },

        data: {
          contractAddress: searchKey,
        },
      });
      if (res.data.responseCode === 200) {
        setHumanSummary(res.data.result);
        // setIsLoadingSearchedData((prev) => prev + 10);
        // console.log("testing 12334445555555", res.data.result);
        setIsSearchTrue(false);
      }
    } catch (error) {
      setIsLoadingSearchedData(0);
      console.log(error);
      // console.log("BSC 10  ---------6  humanSummaryBSCHandler ");
      toast.error("Contract address is not audited ");
      setSearchToken1("");
    }
  };
  // console.log("isLoadingSearchedData", isLoadingSearchedData);
  const getBSCOwnerDetailsHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.getBSCOwnerDetails,
        headers: {
          token: token,
        },
        params: {
          contractAddress: searchKey,
        },
      });
      if (res.data.responseCode === 200) {
        // console.log('result****BSC***', res.data.result)
        setGetBSCOwnerDetails(res.data.result);
        // networkWalletAPIHandler('Binance')
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getBSCOwnerDetailsHandler1 = async (searchKey) => {
    // getBSCTrasactionDetails1(searchToken1);
    // console.log(
    //   "---------------------searchToken1-searchToken1searchToken1searchToken1searchToken1",
    //   searchToken1
    // );
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.getBSCOwnerDetails,
        headers: {
          token: token,
        },
        params: {
          contractAddress: searchKey,
        },
      });
      if (res.data.responseCode === 200) {
        // console.log('result****BSC***', res.data.result)
        setGetBSCOwnerDetails(res.data.result);
        // networkWalletAPIHandler('Binance')
      }
    } catch (error) {
      console.log(error);
    }
  };
  const requestContractDetailsHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: "POST",
        url: apiConfig.requestContract,
        headers: {
          token: token,
        },
        data: {
          contractAddress: searchKey,
          contractType: "Binance",
        },
      });
      if (res.data.responseCode === 200) {
        // console.log('result****BSC***', res.data.result)
        setGetBSCOwnerDetails(res.data.result);
        // networkWalletAPIHandler('Binance')
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const requestContractDetailsHandler1 = async (searchKey) => {
  //   // getBSCTrasactionDetails1(searchToken1);
  //   console.log(
  //     "---------------------searchToken1-searchToken1searchToken1searchToken1searchToken1",
  //     searchToken1
  //   );
  //   try {
  //     const res = await axios({
  //       method: "GET",
  //       url: apiConfig.getBSCOwnerDetails,
  //       headers: {
  //         token: token,
  //       },
  //       params: {
  //         contractAddress: searchKey,
  //       },
  //     });
  //     if (res.data.responseCode === 200) {
  //       // console.log('result****BSC***', res.data.result)
  //       setGetBSCOwnerDetails(res.data.result);
  //       // networkWalletAPIHandler('Binance')
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getBSCTrasactionDetails = async (searchKey) => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.getBSCTrasaction,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        params: {
          contractAddress: searchKey,
        },
      });
      // console.log("res.dataFilter", res);
      if (res.status === 200) {
        setBSCtransactionData(res.data.result);
        setIsLoadingSearchedData((prev) => prev + 25);
        // console.log("BSC 25  ---------7 ");
        setBscScanResult("");
        setBscDetailstokenTransaction(res.data.result.tokenTransaction);
        setBscDetailstransactions(res.data.result.transactions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const buySellBSCFeePercentageHandler = async (searchKey) => {
    // BSCInfoHandler(searchToken);
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.buySellBSCFeePercentage,
        headers: {
          token: token,
        },
        params: {
          contractAddress: searchKey,
        },
      });
      if (res.data.responseCode === 200) {
        setNetworkCal(res.data.result);
      }
    } catch (error) {
      console.log(error);
      // toast.error('Something went wrong')
      setIsLoadingSearchedData(0);
    }
  };
  //bsc--------------------------------------

  const [networkWalletData, setNetworkWalletData] = useState([]);

  const networkWalletAPIHandler = async (netowrk) => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.listWallet,
        headers: {
          token: token,
        },
        params: {
          contractType: netowrk,
        },
      });
      if (res.data.responseCode === 200) {
        setNetworkWalletData(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [BNBpriceUSD, setBNBpriceUSD] = useState([]);
  const myBNBpriceUSD = async () => {
    axios({
      method: "GET",
      url: "https://api.pancakeswap.info/api/v2/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    })
      .then(async (res) => {
        // console.log("res", res);s
        if (res.status === 200) {
          // console.log("stakingList", res.data.data.);
          setBNBpriceUSD(res.data.data.price);

          // setState(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    myBNBpriceUSD();
  }, []);
  const sourceCodeFinder = async (searchToken) => {
    let count = [];
    try {
      const resBSC = await axios({
        method: "GET",
        url: `https://api.bscscan.com/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=YourApiKeyToken`,
      });
      // console.log(resBSC);
      if (resBSC.status === 200) {
        const web3 = (window.web3 = new Web3(window.ethereum));
        const PriceInBNB = (0.01 / BNBpriceUSD).toFixed(18);
        // console.log("PriceInBNB", PriceInBNB);

        getBSCContractDetailHandler(searchToken);
        setBscScanResult(resBSC?.data?.result[0]?.SourceCode);
        setIsSearchTrue(false);
        if (resBSC?.data?.result[0]?.SourceCode !== "") {
          count.push(1);
        }

        // const resETH = await axios({
        //   method: "GET",
        //   url: `https://api.etherscan.io/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=YourApiKeyToken`,
        // });
        // if (resETH.status === 200) {
        //   // setETHScanResult(resETH?.data?.result[0]?.SourceCode)
        //   setIsSearchTrue(false);
        //   if (resETH?.data?.result[0]?.SourceCode !== "") {
        //     count.push(2);
        //   }
        //   const resPolyGon = await axios({
        //     method: "GET",
        //     url: `https://api.polygonscan.com/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=YourApiKeyToken`,
        //   });
        //   if (resPolyGon.status === 200) {
        //     // setPolygonScanResult(resPolyGon?.data?.result[0]?.SourceCode)
        //     setIsSearchTrue(false);
        //     if (resPolyGon?.data?.result[0]?.SourceCode !== "") {
        //       count.push(3);
        //     }
        //   }
        //   if (
        //     resBSC?.data?.result[0]?.SourceCode === "" &&
        //     resETH?.data?.result[0]?.SourceCode === "" &&
        //     resPolyGon?.data?.result[0]?.SourceCode === ""
        //   ) {
        //     toast.error("Report not found!! Please submit your token request.");
        //     setIsLoadingSearchedData(0);
        //     setSearchToken("");
        //     setIsSearchTrue(false);
        //   }
        // }
        // setNetworkcount(count);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const sourceCodeFinder1 = async (searchToken1) => {
    // console.log(
    //   "---------------------searchToken1-searchToken1searchToken1searchToken1searchToken1",
    //   searchToken1
    // );
    let count = [];
    try {
      const resBSC = await axios({
        method: "GET",
        url: `https://api.bscscan.com/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=YourApiKeyToken`,
      });
      // console.log(resBSC);
      if (resBSC.status === 200) {
        const web3 = (window.web3 = new Web3(window.ethereum));
        const PriceInBNB = (0.01 / BNBpriceUSD).toFixed(18);
        // console.log("PriceInBNB", PriceInBNB);

        getBSCContractDetailHandler1(searchToken1);
        setBscScanResult(resBSC?.data?.result[0]?.SourceCode);
        setIsSearchTrue(false);
        // if (resBSC?.data?.result[0]?.SourceCode !== "") {
        //   count.push(1);
        // }

        // const resETH = await axios({
        //   method: "GET",
        //   url: `https://api.etherscan.io/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=YourApiKeyToken`,
        // });
        // if (resETH.status === 200) {
        //   // setETHScanResult(resETH?.data?.result[0]?.SourceCode)
        //   setIsSearchTrue(false);
        //   if (resETH?.data?.result[0]?.SourceCode !== "") {
        //     count.push(2);
        //   }
        //   const resPolyGon = await axios({
        //     method: "GET",
        //     url: `https://api.polygonscan.com/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=YourApiKeyToken`,
        //   });
        //   if (resPolyGon.status === 200) {
        //     // setPolygonScanResult(resPolyGon?.data?.result[0]?.SourceCode)
        //     setIsSearchTrue(false);
        //     if (resPolyGon?.data?.result[0]?.SourceCode !== "") {
        //       count.push(3);
        //     }
        //   }
        //   if (
        //     resBSC?.data?.result[0]?.SourceCode === "" &&
        //     resETH?.data?.result[0]?.SourceCode === "" &&
        //     resPolyGon?.data?.result[0]?.SourceCode === ""
        //   ) {
        //     toast.error("Report not found!! Please submit your token request.");
        //     setIsLoadingSearchedData(0);
        //     setSearchToken("");
        //     setIsSearchTrue(false);
        //   }
        // }
        // setNetworkcount(count);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (searchToken !== "") {
      if (isSearchTrue) {
        if (token) {
          // scanPlanAPIHandler(searchToken)
          sourceCodeFinder(searchToken);
        } else {
          // toast.warn("Please C first!!");
          // history.push('/token-login')
          setIsSearchTrue(false);
          setIsLoadingSearchedData(0);
        }
        // sourceCodeFinder()
      }
    }
  }, [searchToken, isSearchTrue, token]);
  useEffect(() => {
    if (searchToken1 !== "") {
      if (isSearchTrue) {
        if (token) {
          // scanPlanAPIHandler(searchToken)
          sourceCodeFinder1(searchToken1);
        } else {
          // toast.warn("Please C first!!");
          // history.push('/token-login')
          setIsSearchTrue(false);
          // setIsLoadingSearchedData(0);
        }
        // sourceCodeFinder()
      }
    }
  }, [searchToken1, isSearchTrue, token]);
  // console.log(account);
  let data = {
    isLogin,
    saleLiveFair,
    saleEndFair,
    cancledFairFair,
    upcomingFair,
    filledEndedFair,
    cancledFair,
    saleLiveFairPre,
    saleEndFairPre,
    upcomingFairPre,
    filledEndedFairPre,
    cancledFairPre,

    walletAddress,
    errorMsg,
    userData,
    searchToken,
    numberOfPools,
    poolList,
    connectwalletBalance,
    userPoolList,
    livePoolList,
    liveStakingPools,
    FinishedStakingPools,
    unreadCount,
    notificationList,
    usdPrice,
    poolListAPI,
    premiumLists,
    nonPremiumLists,
    fairLists,
    searchTokenDetails,
    networkGasPrice,
    networkLiquidity,
    networkHolders,
    networkTaxCal,
    poolLoader,
    contractInitialData,
    membersTypes,
    membersName,
    // networkHolders,
    // networkTaxCal,
    isLoadingSearchedData,
    isSearchTrue,
    // dashboardDetails,
    humanSummary,
    roundData,
    // isLoadingAds,
    // networkCount,
    bscScanResult,
    feesList,
    // polygonScanResult,
    getBSCOwnerDetails,
    networkWalletData,
    // yourWalletBalance,
    bscDetailstokenTransaction,
    bscDetailstransactions,
    closePoolList,
    stackContractListAPI,
    setView,
    setSearch1,
    search1,
    viewLoader,
    setIsSearchTrue: (data) => setIsSearchTrue(data),
    setSearchToken: (data) => setSearchToken(data),
    setSearchToken1: (data) => setSearchToken1(data),
    setIsLoadingSearchedData: (data) => setIsLoadingSearchedData(data),
    // getAdvertisementHandler: () => getAdvertisementHandler(),
    getStackingListAPIHandler: () => getStackingListAPIHandler(),
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectToWallet(data),
    poolListingData: (search11) => poolListAPIHandler(search11),
    GetTotalStakedFun: () => GetTotalStaked(),
    ListFeesFun: () => ListFees(),
    ListMembersDataFun: () => ListMembersData(),
    logoutHanlder: () => {
      setUserData();
      setIsLogin(false);
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("walletName");
      deactivate();
    },
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
