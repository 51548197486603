// const baseurl = "http://172.16.2.248:1906"; //uat
//uat

const baseurl = "https://node-ido-bsc.mobiloitte.com"; //uat

// const baseurl = "https://node.launchpadinfo.com"; //master

const user = `${baseurl}/api/v1/user`;
const admin = `${baseurl}/api/v1/admin`;
const statics = `${baseurl}/api/v1/static`;
const fees = `${baseurl}/api/v1/fees`;
const kyc = `${baseurl}/api/v1/kyc`;
const sniffer = `${baseurl}/api/v1/sniffer`;
const membership = `${baseurl}/api/v1/membership`;

const contract = `${baseurl}/api/v1/contract`;
const notification = `${baseurl}/api/v1/notification`;
export const socketURL = "wss://node-kyle.mobiloitte.com";
const apiConfig = {
  connectWallet: `${user}/connectWallet`,
  addPresale: `${user}/addPresale`,
  createStacking: `${user}/createStacking`,
  stackingList: `${user}/stackingList`,
  uploadKYC: `${kyc}/uploadKYC`,
  listKYC: `${kyc}/kycList`,
  presaleList: `${user}/presaleList`,
  upVotePresale: `${user}/upVotePresale`,
  downVotePresale: `${user}/downVotePresale`,
  viewPresale: `${user}/viewPresale/`,
  viewStacking: `${user}/viewStacking/`,
  addLiquidityLocker: `${user}/addLiquidityLocker`,
  updateLiquidityLocker: `${user}/updateLiquidityLocker`,
  updateTokenLocker: `${user}/updateTokenLocker`,
  liquidityLockerList: `${user}/liquidityLockerList`,
  addTokenLocker: `${user}/addTokenLocker`,
  tokenLockerList: `${user}/tokenLockerList`,
  promoteList: `${user}/promoteList`,
  approvePresaleUser: `${admin}/approvePresale`,
  trendingPresaleList: `${user}/trendingPresaleList`,

  contractSummary: `${contract}/contractSummary`,
  //   liquidityScan: `${contract}/liquidityScan`,
  humanSummary: `${contract}/humanSummary`,
  liquidityScan: `${contract}/liquidityScan`,
  getVulnerabilitiesList: `${contract}/getVulnerabilitiesList`,
  holderScan: `${contract}/holderScan`,
  addContract: `${contract}/addContract`,
  contractList: `${contract}/contractList`,
  auditReport: `${contract}/auditReport`,
  contractIssueList: `${contract}/contractIssueList`,

  humanSummaryBSC: `${sniffer}/humanSummary`,

  kycListAdmin: `${admin}/kycList`,
  approveKyc: `${admin}/approveKyc`,
  rejectKyc: `${admin}/rejectKyc`,
  presaleListAdmin: `${admin}/presaleList`,

  viewKyc: `${kyc}/viewKyc`,
  approvePresale: `${admin}/approvePresale`,
  addNewsLetter: `${admin}/addNewsLetter`,
  readNotification: `${notification}/readNotification`,
  addContactUs: `${statics}/addContactUs`,

  listFees: `${fees}/listFees`,
  editFees: `${fees}/editFees`,

  userLatestContractScan: `${sniffer}/userLatestContractScan`,
  BSCholderScan: `${sniffer}/BSCholderScan`,
  BSCInfo: `${sniffer}/tokenInfo`,
  getBSCOwnerDetails: `${sniffer}/getBSCOwnerDetails`,
  getBSCTrasaction: `${sniffer}/getBSCTrasaction`,
  getBSCContractDetail: `${sniffer}/getBSCContractDetail`,
  BSCgetEstimateGasPrice: `${sniffer}/BSCgetEstimateGasPrice`,
  getBscLiquidityScan: `${sniffer}/getBscLiquidityScan`,
  buySellBSCFeePercentage: `${sniffer}/buySellBSCFeePercentage`,
  requestContract: `${sniffer}/requestContract`,

  //membership
  listMembershipPlan: `${membership}/listMembershipPlan`,
  updateMemberShip: `${membership}/updateMemberShip`,
  viewMemberShip: `${membership}/viewMemberShip`,
  deleteMemberShip: `${membership}/deleteMemberShip`,
  addMemberShipPlan: `${membership}/addMemberShipPlan`,
  userStacking: `${membership}/userStacking`,
};

export default apiConfig;
