import tinycolor from "tinycolor2";
const primary = "#007644";
const secondary = "#FF5C93";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";
const lightenRate = 7.5;
const darkenRate = 15;
const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
  },
  overrides: {
    MuiLinearProgress: {
      bar1Determinate: {
        width: "100%",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#007644",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "14px",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
        paddingTop: "0px !important",
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    // MuiFormHelperText:{
    //   error:{
    //     paddingTop:"0px !important"
    //   }
    // },

    MuiSelect: {
      selectMenu: {
        borderColor: "#007644 !important",
        backgroundColor: "white !important",
      },
    },

    MuiListItem: {
      root: {
        width: "100%",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        textAlign: "left",
        alignItems: "center",
        paddingTop: "0px",
        paddingBottom: "0px",
        justifyContent: "flex-start",
        textDecoration: "none",
      },
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "rgba(0, 0, 0, 0.54)",
        padding: "5px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "0 !important",
        },
      },
    },
    MuiStepIcon: {
      text: {
        fill: "#fff",
      },
      root: {
        color: "rgb(255 255 255 / 38%)",
        MuiStepIconActive: {
          color: "#fff",
        },
        "&.MuiStepIcon-completed": {
          color: "#007644",
        },
        "&.MuiStepIcon-active": {
          color: "#007644",
        },
      },
    },

    MuiTableRow: {
      root: {
        color: "inherit",
        display: "table-row",
        outline: "0",
        verticalAlign: "middle",
        // background: "#e9c3eb",
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#007644",
        color: "#fff",
        fontWeight: "500",
        lineHeight: "1.5rem",
      },
      alignRight: {
        textAlign: "center",
        flexDirection: "row-reverse",
      },
      body: {
        color: "black",
      },
      root: {
        display: "table-cell",
        padding: "13px",
        fontSize: "0.875rem",
        textAlign: "center",
        fontWeight: "400",
        lineHeight: "1.43",
        // borderBottom: "1px solid #191822",
        letterSpacing: "0.01071em",
        verticalAlign: "inherit",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "450px",
      },
    },
    MuiTab: {
      root: {
        padding: "6px 12px",
        overflow: "hidden",
        position: "relative",
        fontSize: "16px",
        fontWeight: "bold",
        maxWidth: "264px",
        minWidth: "72px",
        boxSizing: "border-box",
        minHeight: "48px",
        textAlign: "center",
        lineHeight: "1.75",
        whiteSpace: "normal",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        "@media (max-width: 767px)": {
          fontSize: "13px",
        },
      },
    },

    MuiSlider: {
      root: {
        color: "#007644",
        width: "100%",
        cursor: "pointer",
        height: "8px",
        display: "inline-block",
        padding: "13px 0",
        position: "relative",
        boxSizing: "content-box",
        touchAction: "none",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          display: "none",
        },
        "&::before": {
          display: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #007644",
        borderColor: "#007644",
        borderRadius: "5px",
      },
      input: {
        font: "inherit",
        color: "#007644",
        border: "none",
        width: "100%",
        borderRadius: "5px",
        fontSize: "13px",
        height: "0.1876em",
        margin: "0",
        display: "block",
        padding: "6px 0 7px",
        minWidth: "0",
        background: "none",
        boxSizing: "content-box",
        letterSpacing: "inherit",
        "&:focus-visible": {
          // outline: " #007644",
          // border: "1px solid #007644",
        },
      },
      multiline: {
        padding: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#007644",
        height: "1em",
        display: "inline-block",
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        flexShrink: "0",
        userSelect: "none",
        marginRight: "3px",
      },
    },
    MuiAppBar: {
      root: {
        width: "100%",
        display: "flex",
        boxSizing: "border-box",
        flexShrink: "0",
      },
      colorPrimary: {
        color: "#fff",
        backgroundColor: "#15161C",
      },
    },
    MuiMenuItem: {
      root: { color: "#3a055b", padding: "6px" },
    },
    MuiPaper: {
      root: { boxShadow: "none !important" },
      rounded: {
        backgroundColor: " #FFFFFF",
        // width: "100%",
        border: "1px solid #007644",
        borderRadius: "5px",
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: " #FFFFFF",
        border: "0.25px solid #007644",
        borderRadius: "10px",
        "@media (max-width: 767px)": {
          display: "block",
        },
      },
    },
    MuiStep: {
      horizontal: {
        width: "25%",
        "@media (max-width: 767px)": {
          width: "100%",
        },
      },
    },
    MuiButton: {
      text: {
        padding: "0px 8px",
      },
      containedSecondary: {
        color: "#FFFFFF",
        border: "1px solid #fff",
        cursor: "pointer",
        height: "50px",
        fontSize: "14px",
        minWidth: "143px",
        backgroundColor: "#007644",
        boxShadow: "none",
        fontWeight: "600",
        borderRadius: "50px",
        "&:hover": {
          borderColor: "#007644",
          backgroundColor: "#fff",
          color: "#007644",
        },
      },
      outlinedPrimary: {
        color: " #007644",
        border: "1px solid #007644",
        borderRadius: "50px",
        fontSize: "14px !important",
        lineHeight: "16px",
        height: " 35px",
        fontWeight: "600",
        maxWidth: "153px",
        textTransform: "capitalize",
        "@media (max-width:767px)": {},
        "&:hover": {
          color: "#FFFFFF",
          backgroundColor: "#007644",
          border: "1px solid #007644",
        },
      },
      outlinedSecondary: {
        color: "#ffffff",
        border: "1px solid #fff",
        // borderRadius: "50px",
        border: "1px solid rgba(255, 255, 255, 0.6)",
        "&:hover": {
          color: "#FFFFFF",
          background: "linear-gradient(94deg, #FF2E00 -9.6%, #007644 92.11%)",
          border: "1px solid rgba(255, 255, 255, 0.6)",
        },
      },
      root: {
        "&:hover": {
          backgroundColor:
            "linear-gradient(180deg, rgba(248,185,0,1) 16%, rgba(143,84,9,1) 79%)",
        },
        "&.Mui-disabled": {
          color: "#79d9c5 !important ",
        },
      },
      contained: {
        "&:hover": {
          backgroundColor: "linear-gradient(180deg, #F8B900 0%, #8F5409 100%)",
        },
        "&.Mui-disabled": {
          color: "rgb(219 219 219 / 48%)",
        },
      },
      containedPrimary: {
        // borderRadius: "50px",
        fontSize: "14px",
        fontWeight: 600,
        color: "#fff !important",
        transition: "0.5s",
        background: "linear-gradient(94deg, #FF2E00 -9.6%, #007644 92.11%)",
        border: "1px solid rgba(255, 255, 255, 0.6)",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#fff",
        },
      },
    },
    MuiFormControl: {
      root: {
        "&:focus-visible": {
          border: "1px solid #007644 !important",
          outlineColor: "#007644 !important",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff !important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      inputMultiline: {
        padding: "18.5px 14px",
        color: "#007644",
      },
      adornedEnd: {
        paddingRight: 0,
      },
      root: {
        "&:focus-visible": {
          border: "1px solid #007644 !important",
          outlineColor: "#007644 !important",
        },
      },
      multiline: {
        padding: "0px",
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      selectMenu: {
        // border: "1px solid #8a8b8b",
        width: "100%",
        borderRadius: "5px",
        fontSize: "13px",
        height: "0.1876em",
        margin: "0",
        display: "block",
        padding: "10px 12px 7px",
        height: "23px",
        display: "flex",
        alignItems: "center",
      },
      select: {
        "&:focus": {
          borderRadius: "5px",
        },
      },
      icon: {
        color: "#000000",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#007644",
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#3a055b",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 0,
      },
    },
  },
};

export default defaultTheme;
