import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Drawer,
  IconButton,
  List,
  makeStyles,
  Box,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import { GiToken } from "react-icons/gi";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import {
  FaTelegramPlane,
  FaFacebookF,
  FaRedditAlien,
  FaDiscord,
} from "react-icons/fa";
import { RiLock2Fill, RiAdminFill } from "react-icons/ri";
// import { RiAdminFill } from "react-icons/Ri";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { GoRequestChanges, GoVerified } from "react-icons/go";
import { AiFillRocket, AiOutlineBank, AiTwotoneHome } from "react-icons/ai";
// import { FiTrendingUp } from "react-icons/fi";
import { MdGeneratingTokens } from "react-icons/md";
import { FaFileContract } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { FaTwitter, FaMediumM, FaYoutube, FaLinkedinIn } from "react-icons/fa";
import { ImDatabase } from "react-icons/im";
import { FiGithub } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { UserContext } from "src/context/User";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    // background: "radial-gradient(circle, rgba(4,27,15,1) 0%, rgba(0,0,0,1) 100%)",
  },
  drawerOpen: {
    width: drawerWidth,
    background: "linear-gradient(126deg, #dc4321 -9.6%, #1ea069 92.11%)",
    paddingLeft: "0px",
    height: "auto",
    position: "fixed",
    maxHeight: "80vh",
    // background: "#fff.side-navigation-panel-select-option:hover",
    // left: "10px",
    border: "2px solid #FFCOCB",
    overflowX: "hidden",
    overflowY: "auto",
    zIndex: "999",
    // transform: "translateY(-50%)",
    top: "100px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&::-webkit-scrollbar": {
      width: " 3px",
    },
    "&::-webkit-scrollbar-track ": {
      background: " #f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: " #888",
    },
    "&::-webkit-scrollbar-thumb:hover ": {
      background: "#555",
    },
  },
  drawerClose: {
    // left: "10px",
    borderRight: "1px solid #fff",
    zIndex: "999",
    marginTop: "5rem",
    // transform: "translateY(-50%)",
    top: "20px",
    background: "linear-gradient(126deg, #dc4321 -9.6%, #1ea069 92.11%)",
    borderRight: "0",
    height: "30rem",
    // background: "#FFFFFF",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 20,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
    "&::-webkit-scrollbar": {
      width: " 3px",
    },
    "& .side-navigation-panel": {
      pointerEvents: "none",
      [theme.breakpoints.down("sm")]: {
        pointerEvents: "auto",
      },
    },
    "&::-webkit-scrollbar-track ": {
      background: " #f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: " #888",
    },
    "&::-webkit-scrollbar-thumb:hover ": {
      background: "#555",
    },
    "& .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text":
      {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "block",
        },
      },
    "& .side-navigation-panel ul ul": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  /* sidebarList: {
    marginTop: theme.spacing(6),
  }, */
  mobileBackButton: {
    marginTop: theme.spacing(2),
    marginLeft: 13,
    display: " flex",
    alignItems: "center",
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },

    "& button": {
      color: "#007644",
      fontSize: "18px",
    },
  },
  socialIcons: {
    padding: "7px 0",
    width: "210px",
    overflow: "hidden",
    // display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    "& button": {
      "& svg": { color: "#FF2E00", fontSize: "15px" },
    },
  },
  comingbox: {
    "& h4": {
      textAlign: "center",
      marginBottom: "20px",
      border: "2px dotted #007644",
      backgroundColor: "#8ce1bdd4",
      width: "152px",
      padding: "4px",
      fontWeight: "500",
      lineHeight: "1.5",
      fontSize: "13px",
      margin: "20px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      [theme.breakpoints.only("sm")]: {
        // width: "0px",
        fontSize: "10px",
      },
    },
  },
  // comingbox:{
  //   border: "1px dashed #0AC0EC",
  //   height: "70vh",
  //   padding: "50px 0px",
  //   zIndex: "1",
  //   overflow: "hidden",
  //   position: "relative",
  //   background: "rgba(0, 0, 0,0.27)",
  //   borderRadius: "15px",
  //   "& h4": {
  //     fontSize: "40 !important",
  //     fontWeight: "500",
  //     fontWeight: "bold",
  //     color: "#654BA9#fabe25 !important",
  //     marginBottom: "10px",
  //     [theme.breakpoints.down("xs")]: {
  //       fontSize: "40 !important",
  //       fontWeight: "500",
  //       fontWeight: "bold",
  //       color: "#654BA9#fabe25 !important",
  //     },
  //   },
  // },
}));
function Sidebar({ location }) {
  const classes = useStyles();
  var theme = useTheme();
  const user = useContext(UserContext);
  const history = useHistory();
  // const location = useLocation();
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });
  return (
    <Drawer
      onBackdropClick={() => toggleSidebar(layoutDispatch)}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={!isSidebarOpened}
    >
      {/* <div className={classes.toolbar} /> */}
      <Box className={classes.mobileBackButton} mb={2}>
        <IconButton
          className="arrowButton"
          onClick={() => toggleSidebar(layoutDispatch)}
          size="small"
        >
          {isSidebarOpened ? (
            <AiOutlineMenuFold
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <AiOutlineMenuUnfold
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
      </Box>
      <Navigation
        // you can use your own router's api to get pathname
        activeItemId={location.pathname}
        onSelect={({ itemId }) => {
          if (!itemId?.includes("#")) {
            history.push(itemId);
          }
        }}
        items={[
          {
            title: "Home",
            itemId: "/app/dashboard",
            // you can use your own custom Icon component as well
            // icon is optional
            elemBefore: () => <AiTwotoneHome />,
          },

          // {
          //   title: "Pool",
          //   itemId: "/app/Pool",
          //   // you can use your own custom Icon component as well
          //   // icon is optional
          //   elemBefore: () => <MdDashboard />,
          // },

          {
            title: "IDOs Launch",
            // itemId: "#2",
            elemBefore: () => <AiFillRocket />,
            subNav: [
              // {
              //   title: "Create Pre-Sale",
              //   itemId: "/app/create-launchedpad",
              //   // you can use your own custom Icon component as well
              //   // icon is optional
              //   // elemBefore: () => <GoVerified />,
              // },
              {
                title: "Create IDO",
                itemId: "/app/create-launched",
                // you can use your own custom Icon component as well
                // icon is optional
                // elemBefore: () => <GoVerified />,
              },
              // {
              //   title: "Token Creator",
              //   itemId: "/app/tokenform",
              //   // you can use your own custom Icon component as well
              //   // icon is optional
              //   elemBefore: () => <GiToken />,
              // },
              // {
              //   title: "Fair Launch IDO",
              //   itemId: "/app/verifyed-presale",
              //   // you can use your own custom Icon component as well
              //   // icon is optional
              //   // elemBefore: () => <GoVerified />,
              // },
              {
                title: "View IDOs Pool",
                itemId: "/app/launched-live-presale",
                // you can use your own custom Icon component as well
                // icon is optional
                // elemBefore: () => <GoVerified />,
              },
              // {
              //   title: "Past Pre sales",
              //   itemId: "/app/past-presale",
              //   // you can use your own custom Icon component as well
              //   // icon is optional
              //   // elemBefore: () => <GoVerified />,
              // },
              // {
              //   title: "Upcoming Pre sales",
              //   itemId: "/app/upcoming-presale",
              //   // you can use your own custom Icon component as well
              //   // icon is optional
              //   // elemBefore: () => <GoVerified />,
              // },
            ],
          },

          {
            title: "Premium Launch",
            itemId: "#5",
            elemBefore: () => <AiFillRocket />,
            subNav: [
              {
                title: "Apply For Launch",
                // itemId: "/app/public-create-launched",
                itemId: "public-create-launched",
              },
              {
                title: "View Premium Launch",
                itemId: "/app/public-verifyed-presale",
              },
              // {
              //   title: "Live Pre sales",
              //   itemId: "/app/public-live-presale",
              // },
              // {
              //   title: "Past Pre sales",
              //   itemId: "/app/public-past-presale",
              // },
              // {
              //   title: "Upcoming Pre sales",
              //   itemId: "/app/public-upcoming-presale",
              // },
            ],
          },

          {
            title: "Indicchain locker",
            itemId: "#1",
            elemBefore: () => <RiLock2Fill />,
            subNav: [
              {
                title: "Lock LP Tokens",
                itemId: "/app/lock-liquidity",
                // you can use your own custom Icon component as well
                // icon is optional
                // elemBefore: () => <RiLock2Fill />,
              },
              {
                title: "Lock Tokens",
                itemId: "/app/lock-token",
                // you can use your own custom Icon component as well
                // icon is optional
                // elemBefore: () => <RiLock2Fill />,
              },
              // {
              //   title: "View Lockers",
              //   itemId: "/app/lock-token",
              //   // you can use your own custom Icon component as well
              //   // icon is optional
              //   // elemBefore: () => <RiLock2Fill />,
              // },
            ],
          },

          {
            title: "Launch Staking",
            itemId: "#3",
            elemBefore: () => <ImDatabase />,
            subNav: [
              {
                title: "Create staking Pool",
                itemId: "/app/create-stake",
                // you can use your own custom Icon component as well
                // icon is optional
                // elemBefore: () => <RiLock2Fill />,
              },
              {
                title: "View staking pool",
                itemId: "/app/live-staking",
                // you can use your own custom Icon component as well
                // icon is optional
                // elemBefore: () => <RiLock2Fill />,
              },
              // {
              //   title: "Apply For Private Pool",
              //   itemId: "#",
              //   // you can use your own custom Icon component as well
              //   // icon is optional
              //   // elemBefore: () => <RiLock2Fill />,
              // },
            ],
          },
          {
            title: "Indicchain Staking",
            itemId: "/app/sheild-stake",

            elemBefore: () => <GiToken />,
          },
          {
            title: "Token Creator",
            itemId: "/app/token",
            // you can use your own custom Icon component as well
            // icon is optional
            elemBefore: () => <GiToken />,
          },

          {
            title: "Contract Sniffer",
            // itemId: "/app/sniffer",
            itemId: "/app/contract-sniffer",
            elemBefore: () => <FaFileContract />,
          },

          {
            title: "KYC & Audit",
            // itemId: "/app/sniffer",
            itemId: "/app/kyc",
            elemBefore: () => <AiOutlineBank />,
          },
          {
            title: "Indicchain MultiSender",
            // itemId: "/app/sniffer",
            itemId: "/app/multiSender",
            elemBefore: () => <MdGeneratingTokens />,
          },
          {
            title: "Membership",
            // itemId: "/app/sniffer",
            itemId: "/app/public-membership",
            elemBefore: () => <AccessibilityIcon style={{ color: "#fff" }} />,
          },
          // {
          //   title: "KYC",
          //   itemId: "/app/kyc",
          //   elemBefore: () => <AiOutlineBank />,
          // },
          {
            title: "About Indicchain",
            itemId: "/app/About",
            elemBefore: () => <AiOutlineInfoCircle />,
          },
        ]}
      />

      {user?.userData?.userType == "Admin" && (
        <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            history.push(itemId);
          }}
          items={[
            // {
            //   title: "Presale Request",
            //   itemId: "/app/pre-sale",
            //   elemBefore: () => <GoRequestChanges />,
            // },
            {
              title: "Admin Control",
              itemId: "/app/admin-control",
              elemBefore: () => <GoRequestChanges />,
            },
          ]}
        />
      )}

      <Box className="desktopSocialICons">
        {isSidebarOpened ? (
          <>
            {user.membersName && (
              <Box className={classes.comingbox} align="center" mb={2}>
                {" "}
                <h4
                  style={{ textAlign: "center", textTransform: "capitalize" }}
                >
                  {user.membersName}
                </h4>
              </Box>
            )}
            <Box className={classes.comingbox} align="center" mb={2}>
              {" "}
              <h4 style={{ textAlign: "center" }}>
                {" "}
                <AiFillRocket /> &nbsp; INDICCHAIN : 0.1$
              </h4>
            </Box>
            <Box className={classes.socialIcons} align="center">
              <IconButton>
                <TwitterIcon />
              </IconButton>
              <IconButton>
                <InstagramIcon />
              </IconButton>
              <IconButton>
                <FaTelegramPlane />
              </IconButton>
              <IconButton>
                <FaFacebookF />
              </IconButton>
              <IconButton>
                <FaMediumM />
              </IconButton>
              <br />
              <IconButton>
                <FaYoutube />
              </IconButton>
              <IconButton>
                <FaRedditAlien />
              </IconButton>
              <IconButton>
                <FaDiscord />
              </IconButton>
              <IconButton>
                <FaLinkedinIn />
              </IconButton>
              <IconButton>
                <FiGithub />
              </IconButton>
            </Box>
          </>
        ) : (
          ""
        )}
      </Box>
      <Box className="MobileSocialICons">
        {!isSidebarOpened ? (
          <>
            <Box className={classes.comingbox} align="center" mb={2}>
              <h4 style={{ textAlign: "center" }}> Your Level : Gold</h4>
            </Box>
            <Box className={classes.socialIcons} align="center">
              <IconButton>
                <TwitterIcon />
              </IconButton>
              <IconButton>
                <InstagramIcon />
              </IconButton>
              <IconButton>
                <FaTelegramPlane />
              </IconButton>
              <IconButton>
                <FaFacebookF />
              </IconButton>
              <IconButton>
                <FaMediumM />
              </IconButton>
              <br />
              <IconButton>
                <FaYoutube />
              </IconButton>
              <IconButton>
                <FaRedditAlien />
              </IconButton>
              <IconButton>
                <FaDiscord />
              </IconButton>
              <IconButton>
                <FaLinkedinIn />
              </IconButton>
              <IconButton>
                <FiGithub />
              </IconButton>
            </Box>
          </>
        ) : (
          ""
        )}
      </Box>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
